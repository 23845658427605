import 'vite/modulepreload-polyfill'
import Alpine from 'alpinejs'

import SwiperCarousel from '@scripts/components/swiper-carousel'

window.Alpine = Alpine

document.addEventListener('alpine:init', () => {
  // store

  // components
  Alpine.data('swiperCarousel', SwiperCarousel)
  // sections
})

Alpine.start()

let fetchTriggered = false

// Function to fetch and process cart data
const fetchCartData = () => {
  console.log('Fetching cart data')

  fetch('/cart.js')
    .then((res) => res.json())
    .then((res) => {
      console.log(res, 'res')
      const { items } = res

      items.forEach((item) => {
        const lineItemElement = document.querySelector(`#monster-upsell-cart .mu-cart-item[data-variant-id="${item.variant_id}"]`) as HTMLElement | null

        if (item.properties._preorder) {
          if (lineItemElement && !lineItemElement.querySelector('[data-preorder-message]')) {
            const markup = `<p data-preorder-message style="margin-top: 12px;">${item.properties._preorder}</p>`
            const flexContainer = lineItemElement.querySelector('.mu-flex-1.mu-flex-col') as HTMLElement | null
            if (flexContainer) {
              flexContainer.insertAdjacentHTML('beforeend', markup)
            }
          }
        } else {
          // Remove the `[data-preorder-message]` if it exists
          if (lineItemElement) {
            const preorderMessage = lineItemElement.querySelector('[data-preorder-message]')
            if (preorderMessage) {
              preorderMessage.remove()
            }
          }
        }
      })

      // Reset the flag after processing the fetch results
      fetchTriggered = false
    })
    .catch((error) => {
      console.error('Error fetching cart data:', error)

      // Reset the flag in case of an error
      fetchTriggered = false
    })
}

// Create a MutationObserver to watch for the #monster-upsell-cart element
const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    mutation.addedNodes.forEach((node) => {
      if (node instanceof HTMLElement && node.id === 'monster-upsell-cart') {
        // Stop observing the document body once the element is found
        observer.disconnect()

        // Create a new observer to watch for changes inside #monster-upsell-cart
        const innerObserver = new MutationObserver((innerMutations) => {
          innerMutations.forEach((innerMutation) => {
            if (innerMutation.type === 'attributes' && innerMutation.attributeName === 'class') {
              const target = innerMutation.target as HTMLElement
              if (target.classList.contains('mu_openned') && !fetchTriggered) {
                // Set the flag to true to prevent multiple fetch operations
                fetchTriggered = true
                console.log('mu_openned class added')
                fetchCartData()
              }
            }

            if (innerMutation.type === 'childList' && !fetchTriggered) {
              const target = innerMutation.target as HTMLElement
              if (target.id === 'mu-items-container') {
                // Set the flag to true to prevent multiple fetch operations
                fetchTriggered = true
                console.log('Child list changed in mu-items-container')
                fetchCartData()
              }
            }
          })
        })

        // Start observing #monster-cart-wrapper for attribute changes and childList changes
        const monsterCartWrapper = node.querySelector('#monster-cart-wrapper') as HTMLElement | null
        if (monsterCartWrapper) {
          innerObserver.observe(monsterCartWrapper, {
            attributes: true,
            attributeFilter: ['class'],
            childList: true,
            subtree: true // Observe all descendants
          })

          const itemsContainer = monsterCartWrapper.querySelector('#mu-items-container') as HTMLElement | null
          if (itemsContainer) {
            innerObserver.observe(itemsContainer, {
              childList: true,
              subtree: false // Only observe direct children
            })
          }
        }
      }
    })
  })
})

// Start observing the document body for child node additions
observer.observe(document.body, {
  childList: true,
  subtree: true
})
