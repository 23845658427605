import Swiper from 'swiper'
import { Scrollbar, Navigation, Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const SwiperCarousel = ({
  showScrollbarTablet = false,
  showNavigationTablet = false,
  showPaginationTablet = false,
  showNavigationDesktop = false,
  showPaginationDesktop = false,
  showScrollbarDesktop = false,
  showNavigationMobile = false,
  showPaginationMobile = false,
  showScrollbarMobile = true,
  slidesPerViewDesktop = 3,
  slidesPerViewMobile = 1.5,
  slidesPerViewTablet = 2.5,
  spaceBetweenDesktop = 30,
  spaceBetweenMobile = 8,
  slidesOffsetAfterDesktop = 0,
  slidesOffsetBeforeDesktop = 0,
  slidesOffsetAfterTablet = 0,
  slidesOffsetBeforeTablet = 0,
  slidesOffsetAfterMobile = 0,
  slidesOffsetBeforeMobile = 0,
  loop = false,
  centeredSlidesMobile = false,
  centeredSlidesDesktop = false,
  widthMobile = null,
  widthDesktop = null
}) => ({
  swiper: null,
  disableNext: false,
  slidesCount: 0,
  activeColorIndex: 0,
  nextCounter: 0,

  init () {
    this.initSwiper()
  },

  initSwiper () {
    this.swiper = new Swiper(this.$refs.swiper, {
      breakpoints: {
        1024: {
          scrollbar: {
            enabled: showScrollbarDesktop
          },
          navigation: {
            enabled: showNavigationDesktop,
            nextEl: this.$refs.navNext,
            prevEl: this.$refs.navPrev
          },
          pagination: {
            enabled: showPaginationDesktop,
            el: this.$refs.swiperPagination
          },
          spaceBetween: spaceBetweenDesktop,
          slidesOffsetAfter: slidesOffsetAfterDesktop,
          slidesOffsetBefore: slidesOffsetBeforeDesktop,
          slidesPerView: slidesPerViewDesktop,
          centeredSlides: centeredSlidesDesktop,
          width: widthDesktop
        },
        768: {
          scrollbar: {
            enabled: showScrollbarTablet
          },
          navigation: {
            enabled: showNavigationTablet,
            nextEl: this.$refs.navNext,
            prevEl: this.$refs.navPrev
          },
          pagination: {
            enabled: showPaginationTablet,
            el: this.$refs.swiperPagination
          },
          slidesOffsetAfter: slidesOffsetAfterTablet,
          slidesOffsetBefore: slidesOffsetBeforeTablet,
          slidesPerView: slidesPerViewTablet
        }
      },
      scrollbar: {
        draggable: true,
        enabled: showScrollbarMobile,
        el: '.swiper-scrollbar'
      },
      navigation: {
        enabled: showNavigationMobile,
        nextEl: this.$refs.navNext,
        prevEl: this.$refs.navPrev
      },
      pagination: {
        enabled: showPaginationMobile,
        el: this.$refs.swiperPagination
      },
      modules: [Navigation, Pagination, Scrollbar],
      slidesOffsetAfter: slidesOffsetAfterMobile,
      slidesOffsetBefore: slidesOffsetBeforeMobile,
      speed: 300,
      spaceBetween: spaceBetweenMobile,
      slidesPerView: slidesPerViewMobile,
      resistanceRatio: 0.85,
      loop,
      centeredSlides: centeredSlidesMobile,
      width: widthMobile,
      centerInsufficientSlides: true,
      loopAddBlankSlides: false,
      on: {
        init: (swiper) => {
          this.slidesCount = swiper.slides.length

          this.activeColorIndex = swiper.slides.findIndex(slide => slide.querySelector('.color-swatch--active'))
        }
      }
    })

    // Determine the initial page based on the activeColorIndex
    const initialPage = this.calculateInitialPage()

    // Slide to the calculated initial page
    this.slideSwiper(initialPage)
  },

  slidesPerPage () {
    // Determine the number of slides per page based on screen size
    return window.matchMedia('(min-width: 1024px)').matches ? 16 : 8
  },

  calculateInitialPage () {
    const slidesPerPage = this.slidesPerPage()

    // Calculate the page number that contains the active color index
    let pageNumber = Math.floor(this.activeColorIndex / slidesPerPage) + 1

    // Determine the divisor based on screen size
    const divisor = window.matchMedia('(min-width: 1024px)').matches ? 15 : 7

    // Adjust the page number if activeColorIndex is divisible by the divisor
    if (this.activeColorIndex >= divisor - 1 && this.activeColorIndex % divisor === 0 && this.activeColorIndex !== 0) {
      pageNumber += 1
    }

    return pageNumber
  },

  slideSwiper (pageNumber) {
    const slidesPerPage = this.slidesPerPage()

    // Calculate the starting slide index for the specified page
    const slideIndex = (pageNumber - 1) * slidesPerPage

    // Check if the calculated index is within the range of available slides
    if (slideIndex >= 0 && slideIndex < this.slidesCount) {
      this.swiper.slideTo(slideIndex)
    } else {
      console.warn('Page number is out of range')
    }

    if (this.swiper.isEnd) {
      this.disableNext = true
      this.nextCounter = Math.ceil(this.swiper.slides.length / this.swiper.activeIndex)
    } else {
      this.disableNext = false
    }
  },

  handleSwiperNavigate (e, direction) {
    e.preventDefault()
    e.stopPropagation()

    if (direction === 'next') {
      this.swiper.slideNext()
      this.nextCounter = this.nextCounter + 1
    } else {
      this.swiper.slidePrev()
      this.nextCounter = this.nextCounter - 1
    }

    if ((this.slidesCount % 6) - this.nextCounter <= 1) {
      this.disableNext = true
    } else {
      this.disableNext = false
    }
  }
})

export default SwiperCarousel
